import React from 'react';
import { EmblaOptionsType } from 'embla-carousel';
import emblaCarouselClassNames from 'embla-carousel-class-names';
import { PbsKidsVideo, VideoContentCard } from '@/types/pbskids-graph';
import { MediaContext } from '@/types/mediaContext';
import ArrowButtonLeft from '@/components/base/ArrowButton/ArrowButtonLeft';
import ArrowButtonRight from '@/components/base/ArrowButton/ArrowButtonRight';
import MediaList from '../MediaList';
import { usePrevNextButtons } from '@/components/base/CarouselNavButtons';
import styles from './RowLowerModule.module.scss';
import sectionStyles from '@/components/base/PageSectionStack/PageSectionStack.module.scss';
import { useAccessibleCarousel } from '@/components/modules/CarouselModule/useAccessibleCarousel';
interface Props {
  cardStyle?: string,
  context?: MediaContext,
  items?: PbsKidsVideo[],
  linkItems?: VideoContentCard[],
  listId?: string,
  title?: string,
}

const RowLowerModule: React.FC<Props> = ({
  cardStyle = 'mezzanine',
  context,
  items,
  linkItems,
  listId,
  title,
}) => {
  const cardOptions = (cardstyle: string): EmblaOptionsType => {
    switch (cardstyle) {
      case 'poster':
      case 'largeSquare':
        return {
          containScroll: false,
          loop: true,
          inViewThreshold: .6,
          skipSnaps: false,
          breakpoints: {
            '(min-width: 568px)': {
              align: 'start',
              dragFree: true,
            },
          },
        };
      case 'smallSquare':
        return {
          align: 'center',
          loop: true,
          inViewThreshold: .5,
          skipSnaps: false,
          containScroll: false,
          breakpoints: {
            '(min-width: 568px)': {
              align: 'start',
              dragFree: true,
              inViewThreshold: .5,
            },
          },
        };
      default: return {
        containScroll: false,
        loop: true,
        inViewThreshold: 1,
        skipSnaps: false,
        breakpoints: {
          '(min-width: 568px)': {
            align: 'start',
            dragFree: true,
          },
          '(min-width: 1440px': {
            inViewThreshold: .3,
          },
        },
      };
    }
  };

  const emblaOptions = cardOptions(cardStyle);

  // When scrolling multiple link cards per click/swipe, duplicate the list
  // in the case of orphaned scrolls
  const linkItemList = () => {
    const scrolling = emblaOptions.slidesToScroll as number;

    if (linkItems && !(scrolling === 1) && !(linkItems.length % scrolling === 0)) {
      let newItemList: VideoContentCard[] = linkItems;

      while (!(newItemList.length % scrolling === 0) && newItemList.length < 30) {
        newItemList = newItemList.concat(linkItems);
      }
      return newItemList;
    } else return linkItems;
  };

  const videoItemList = () => {
    if (items) {
      if (!(items.length % 2)) {
        return items.concat(items);
      } else return items;
    }
  };

  const [ emblaRef, emblaApi ] = useAccessibleCarousel(
    emblaOptions,
    [
      emblaCarouselClassNames({ inView: `${styles.slideInView}`, snapped: `${styles.slideSnapped}`, dragging: `${styles.isDragging}` }),
    ],
  );

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const videos = videoItemList();
  const links = linkItemList();

  const NavButtons = () => {
    return <>
      <ArrowButtonLeft
        onClick={onPrevButtonClick}
        disabled={prevBtnDisabled}
        edgeStyle={true}
        moreClasses={`${styles.navButton} ${styles.navLeft}`}
      />
      <ArrowButtonRight
        onClick={onNextButtonClick}
        disabled={nextBtnDisabled}
        edgeStyle={true}
        moreClasses={`${styles.navButton} ${styles.navRight}`}
      />
    </>;
  };

  return (videos?.length || links?.length) &&
    <section
      data-carousel-wrapper='true'
      role='group'
      aria-label={title}
      className={`${sectionStyles.isSlider} ${styles[cardStyle as keyof typeof styles] || ''}`}
    >
      { title ?
        <h2 className={ styles.title }>
          { title }
        </h2> :
        <div className={ `${styles.title} ${styles.emptyTitle}` }>
          &nbsp;
        </div>
      }
      <div className={`${styles.embla} ${styles[cardStyle as keyof typeof styles] || ''}`}>
        <div className={styles.emblaViewport} ref={emblaRef}>
          <MediaList
            cardStyle={ cardStyle }
            context={ context }
            items={ videos }
            linkItems={ links }
            listId={ listId }
            isSlider={ true }
          />
        </div>

        <div className={styles.navWrapper}>
          <NavButtons/>
        </div>
      </div>
    </section>;
};

export default RowLowerModule;
