import React, { useEffect, useState } from 'react';
import { PbsKidsVideo, VideoContentCard } from '@/types/pbskids-graph';
import { MediaContext } from '@/types/mediaContext';
import Carousel from '@/components/base/Carousel';
import RowLowerModule from '@/components/modules/RowLowerModule';
import styles from './CarouselModule.module.scss';

interface Props {
  cardStyle?: string,
  context?: MediaContext,
  inMasthead?: boolean,
  items?: PbsKidsVideo[],
  linkItems?: VideoContentCard[],
  listId?: string,
}

enum ContentType {
  videos = 'videos',
  videoContentCards = 'videoContentCards',
}

const CarouselModule: React.FC<Props> = ({
  cardStyle = 'mezzanine',
  context,
  inMasthead,
  items,
  linkItems,
  listId,
}) => {
  const [ isDesktop, setIsDesktop ] = useState<boolean>();

  const listContentType = () => {
    if (items?.length) {
      return ContentType.videos;
    } else if (linkItems?.length) {
      return ContentType.videoContentCards;
    }
  };

  const getCarouselList = () => {
    switch (listContentType()) {
      case ContentType.videos:
        return items;
      case ContentType.videoContentCards:
        return linkItems;
      default:
        return;
    }
  };

  const carouselList = getCarouselList();

  const setDisplaySize = () => {
    setIsDesktop(window.innerWidth > 567);
  };

  useEffect(() => {
    setDisplaySize();
    window.addEventListener('resize', setDisplaySize);

    return () => {
      window.removeEventListener('resize', setDisplaySize);
    };
  }, []);

  if (typeof(isDesktop) === 'undefined' || !carouselList?.length) {
    return null;
  }

  return (
    <section className={styles.carouselModule}>
      { !isDesktop &&
        <RowLowerModule
          items={ items }
          linkItems={ linkItems }
          cardStyle='mezzanine'
          context={ context }
          listId={ listId }
        />
      }

      { isDesktop &&
        <Carousel
          cardStyle={ cardStyle }
          context={ context}
          inMasthead={ inMasthead }
          items={ carouselList }
          listId={ listId }
        />
      }
    </section>
  );
};

export default CarouselModule;
